import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePagesSetting() {
  // Use toast
  const toast = useToast()

  const createPage = queryParams => axios
    .post('/auth/pages', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Page was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating page',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updatePage = queryParams => axios
    .put(`/auth/pages/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Page was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating page',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchPage = ID => axios
    .get(`/auth/pages/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching page item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    createPage,
    updatePage,
    fetchPage,
  }
}
